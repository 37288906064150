import {
    defineStore,
} from 'pinia';

export const useGlobalStore = defineStore('globalState', {
    // 为了完整类型推理，推荐使用箭头函数
    state: (): {
        theme: 'light' | 'dark';
        loading: boolean;
        isCardUser: boolean;
        telegram: {
            initData: string;
            initDataUnsafe: WebAppInitData | null;
        },
        wkey: string;
        jwt: string;
        hasBackHistory: boolean;
        isNobepay: boolean;
        lang: string;
        debug: boolean;
        initWindowHeight: number;
        version: string;
        echargeFee: {
            alipay: number;
            ach: number;
            wxpay: number;
            visa: number;
            usdt: number;
            stripe: string,
        },
        echargeFeeNobe: {
            alipay: number;
            ach: number;
            wxpay: number;
            visa: number;
            usdt: number;
            stripe: string,
        }
    } => {
        const config = useRuntimeConfig();
        const isDev = config.public.isDev;
        return {
            theme: 'light',
            loading: true,
            isCardUser: false,
            telegram: {
                initData: '',
                initDataUnsafe: null,
            },
            wkey: '',
            jwt: '',
            hasBackHistory: false,
            isNobepay: false,
            lang: 'en-US',
            debug: isDev || Boolean(config.public.DEBUG),
            initWindowHeight: 0,
            version: config.public.VERSION,
            echargeFee: {
                alipay: 1.5,
                ach: 0,
                wxpay: 1.5,
                visa: 3,
                usdt: 2,
                stripe: '3% + $0.3',
            },
            echargeFeeNobe: {
                alipay: 1,
                ach: 0,
                wxpay: 0,
                visa: 0,
                usdt: 2,
                stripe: '3% + $0.3',
            },
        }
    },
    actions: {
        hideKeyboard() {
            if (process.client) {
                if (document.activeElement instanceof HTMLElement) {
                    if (window.innerHeight < this.initWindowHeight - 100) {
                        document.activeElement.blur();
                        document.querySelectorAll('input, textarea').forEach((el) => {
                            (el as HTMLInputElement)?.blur();
                        });
                    } else if (window.visualViewport && window.visualViewport?.height < this.initWindowHeight - 100) {
                        document.activeElement.blur();
                        document.querySelectorAll('input, textarea').forEach((el) => {
                            (el as HTMLInputElement)?.blur();
                        });
                    }
                }
            }
        },
    },
    persist: {
        paths: ['theme', 'telegram', 'jwt', 'lang', 'authToken', 'wkey'],
        storage: persistedState.localStorage,
    },
})